<template>
  <ContainerBasic
    container-type="FULL_WIDTH"
    :class="wrapperClasses"
    class="styled-section"
    :style="blockData.bgColor + blockData.textColor"
  >
    <PageContent
      :page-content-entries="blockData.children"
      :no-header="!isHeader"
    />
  </ContainerBasic>
</template>

<script setup lang="ts">
import { provide } from 'vue';
import {
  ELayoutWidth,
  EBorder,
  ELayoutSpacing,
  ELayoutPadding,
} from '@/@types/styled-section';
import { styledSectionTextColor } from '@/injectionSymbols';
import ContainerBasic from '@/components/components/container-basic.vue';
import type { StyledSectionData } from '../blockType';
import PageContent from '@/components/pageContent/vdv/PageContent.vue';

const props = defineProps({
  blockData: {
    type: Object as PropType<StyledSectionData>,
    required: true,
    default: {} as StyledSectionData,
  },
  isHeader: {
    type: Boolean,
    required: false,
    default: false,
  },
});

provide(styledSectionTextColor, props.blockData.textColor);

const wrapperClasses = getWrapperClasses();

function getWrapperClasses() {
  const size = props.blockData.size;
  let wrapperClasses = '';

  // Container-Width
  switch (size) {
    case ELayoutWidth.STANDARD:
      wrapperClasses = wrapperClasses.concat(
        ' ',
        'w-full max-w-screen-lg mx-auto',
      );
      break;
    case ELayoutWidth.CONTENT:
      wrapperClasses = wrapperClasses.concat(
        ' ',
        'w-full max-w-screen-xl mx-auto',
      );
      break;
    case ELayoutWidth.FULL:
      wrapperClasses = wrapperClasses.concat(' ', 'w-full mx-auto');
      break;
    default:
      wrapperClasses = wrapperClasses.concat(' ', 'w-full');
      break;
  }
  // Add padding if a background color is present
  if (props.blockData.bgColor !== '' && props.blockData.bgColor != undefined) {
    wrapperClasses = wrapperClasses.concat(' ', 'px-0 md:px-sm flex');
  }

  // Other Style-Options like margins/paddings
  wrapperClasses = wrapperClasses.concat(' ', getBorderStyle());
  wrapperClasses = wrapperClasses.concat(' ', getSpacing());
  wrapperClasses = wrapperClasses.concat(' ', getPadding());

  return wrapperClasses;
}

function getBorderStyle() {
  switch (props.blockData.border) {
    case EBorder.NONE:
      return '';
    case EBorder.FULL_COLORSECTION:
      return 'border-1 border-solid border-activesection-alpha';
    case EBorder.TEMPLATE_BORDER_NO_PADDING:
    case EBorder.TEMPLATE_BORDER_NO_PADDING_POST:
      return 'touch:md:border-1 touch:md:border-solid touch:md:border-grey lg:border-1 lg:border-solid lg:border-grey';
    case EBorder.TEMPLATE_BORDER_PADDING:
      return 'touch:md:border-1 touch:md:border-solid touch:md:border-grey touch:md:px-md desktop:md:px-md lg:border-1 lg:border-solid lg:border-grey lg:px-md';
    default:
      return '';
  }
}

function getSpacing() {
  switch (props.blockData.spacing) {
    case ELayoutSpacing.NONE:
      return '!mt-0 md:!mt-0';
    case ELayoutSpacing.STANDARD:
      return '!mt-md md:!mt-xl';
    default:
      return '!mt-md md:!mt-xl';
  }
}

function getPadding() {
  switch (props.blockData.padding) {
    case ELayoutPadding.NONE:
      return 'styled-section__no-padding';
    case ELayoutPadding.STANDARD:
      return 'pb-md md:pb-xl';
    case ELayoutPadding.TEMPLATE:
      return '!pb-0 pt-md';
    case ELayoutPadding.TEMPLATE_WITH_GALLERY:
      return '!pb-0';
    case ELayoutPadding.ONLY_BOTTOM:
      return 'styled-section__no-padding !pt-0 pb-md md:pb-xl';
    case ELayoutPadding.ONLY_BOTTOM_LG:
      return 'styled-section__no-padding !pt-0 pb-md md:pb-lg';
    case ELayoutPadding.ONLY_TOP:
      return '!pb-0 styled-section__no-padding';
    default:
      return 'pb-md md:pb-xl';
  }
}
</script>

<style scoped lang="postcss"></style>
